import { FaDiscord, FaGlobe } from "react-icons/fa";

const { Flex, Text, Divider, Link } = require("@chakra-ui/react");
const { TiHome } = require("react-icons/ti");
const { useState } = require("react");
const { IoIosChatbubbles } = require("react-icons/io");

function NavItem({ children, active, link }) {
    const [color, setColor] = useState(active ? "#EBEBEB" : "#A2A0A1");

    return (
        <Flex direction={"column"} gap={"8px"} mt={active ? "8px" : "0px"}>
            <Link href={link} _hover={{ textDecoration: "none" }}
            onMouseEnter={() => setColor('#EBEBEB')}
            onMouseLeave={() => setColor(active ? "#EBEBEB" : "#A2A0A1")}>
            <Flex direction={"row"} gap={"8px"}  alignItems={"center"}>
                {children === "STRONA GŁÓWNA" && <TiHome color={color} style={{transition: "0.5s ease-out", }} />}
                {children === "FORUM" && <IoIosChatbubbles color={color} style={{transition: "0.5s ease-out", }} />}
                {children === "DISCORD" && <FaDiscord color={color} style={{transition: "0.5s ease-out", }} />}
                {children === "WIKIPEDIA" && <FaGlobe color={color} style={{transition: "0.5s ease-out", }} />}
                <Text
                hideBelow='xl'
                style={{
                    _hover: {
                        fontSize: "17px",
                    },
                    transition: "0.5s ease-out",
                    whiteSpace: "nowrap",
                }}
                fontWeight="medium" fontSize={"15px"} textAlign={"center"} lineHeight={"normal"} textColor={color} cursor={"pointer"}>{children}</Text>
            </Flex>
        </Link>
            { active && <Divider borderWidth={"1px"} borderColor={"#fff"} /> }
        </Flex >
    );
}

export default NavItem;