import { Box, Divider, Flex, Image,Link } from "@chakra-ui/react";
import { FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import NavItem from "./NavItem";
import { useState } from "react";

import ButtonSocial from "./ButtonSocial";
function Navbar() {
    const [coloryt, setColoryt] = useState("#EBEBEB");
    const [colortt, setColortt] = useState("#EBEBEB");
    const [colorig, setColorig] = useState("#EBEBEB");

    return (
        <Flex direction={"row"}  alignItems={"center"} justifyContent={{base: "space-between", xl: "unset"}} p={{ base: "0px 30px", xl: "0px" }} ml={"auto"} mr={"auto"} position="relative" mt={"10px"} zIndex={100}>
            <Image src={"logo.svg"} alt={"socialproject.pl"} h={{ base: "65px", lg: "100px" }} w={{ base: "65px", lg: "100px" }}  pointerEvents={"none"}/>
            <Flex ml={{ base: "40px", lg: "50px" }} p={"28px 0px"} gap={{base: "20px", lg: "35px"}} alignItems={"center"}>
                <NavItem active={true} link={"#"}>
                    STRONA GŁÓWNA
                </NavItem>
                <NavItem link={"#"}>FORUM</NavItem>
                <NavItem link={"https://discord.gg/socialproject"}>DISCORD</NavItem>
                <NavItem link={"#"}>WIKIPEDIA</NavItem>
            </Flex>
            <Flex p={"28px 0px"} gap={"20px"} alignItems={"center"} ml={{ base: "30px", xl: "auto" }} hideBelow={"md"}>
                <Box as={Link} href="https://www.youtube.com/@Socialprojectpl" target='_blank'>
                    <FaYoutube size={"18px"} color={coloryt} cursor={"pointer"}
                    style={{transition: "0.5s ease-out" }}
                    onMouseEnter={() => setColoryt('#ff0808')}
                    onMouseLeave={() => setColoryt("#EBEBEB")} />
                </Box>
                <Box as={Link} href="https://www.tiktok.com/@socialprojectpl" target='_blank'>
                    <FaTiktok size={"18px"} color={colortt} cursor={"pointer"} style={{transition: "0.5s ease-out", _hover: {boxShadow:"0px 0px 72.2px 0px rgba(4, 4, 4, 0.44), 0px 2px 4px 0px rgba(0, 0, 0, 0.17)"} }}
                    onMouseEnter={() => setColortt('#040404')}
                    onMouseLeave={() => setColortt("#EBEBEB")} />
                </Box>
                <Box as={Link} href="https://www.instagram.com/socialproject.pl" target='_blank'>
                    <FaInstagram size={"18px"} color={colorig} cursor={"pointer"} style={{transition: "0.5s ease-out", _hover: {boxShadow:"0px 0px 72.2px 0px rgba(16, 107, 255, 0.44), 0px 2px 4px 0px rgba(0, 0, 0, 0.17)"} }} 
                    onMouseEnter={() => setColorig('#DD2A7B')}
                    onMouseLeave={() => setColorig("#EBEBEB")} />
                </Box>
                <Box height={"18px"}>
                    <Divider orientation='vertical' />
                </Box>
                <ButtonSocial text="DOŁĄCZ DO NAS" href={"https://discord.gg/socialproject"} />
            </Flex>
        </Flex>
    );
}

export default Navbar;