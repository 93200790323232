import { Box, Flex, Text, Image, Avatar, AvatarGroup, Divider, Link } from '@chakra-ui/react';
import Questions from './Questions';
import { useEffect, useState } from 'react';
import ButtonDiscord from './ButtonDiscord';
import { FaYoutube, FaTiktok } from 'react-icons/fa';

function InfoAbout() {

    const [data, setData] = useState(null)

    useEffect(() => {
        fetch('https://api.socialproject.pl/discordInfo')
            .then(response => response.json())
            .then(data => setData(data))
            .catch(error => console.log(error))
    }, [])

    const info = [
        {
            text: "DOŚWIADCZONY",
            subText: "ZESPÓŁ",
            desc: "Nasi developerzy to elita branży, posiadająca wieloletnie doświadczenie, które teraz inwestują w projekt. Ich nieustanny rozwój przekłada się na jakość i innowacyjność naszych rozwiązań.",
            icon: <Image src='i/icon1.svg' mt={"15px"} />
        },
        {
            text: "PRZEMYŚLANA",
            subText: "ROZGRYWKA",
            desc: "W naszym projekcie stawiamy na przyjazną rozgrywkę dla każdego gracza. Oferujemy rozbudowane systemy, które uwzględniają tryb kooperacyjny, zróżnicowane aktywności, codzienne zadania i osiągnięcia.",
            icon: <Image src='i/icon2.svg' mt={"15px"} />
        },
        {
            text: "WIZJA",
            subText: "PRZYSZŁOŚCI",
            desc: "Jesteśmy zobowiązani do ciągłego rozwoju, wprowadzania innowacji i tworzenia społeczności graczy, której głos jest ważny. Planujemy unikalne rozwiązania, które uczynią nasz projekt wyjątkowym.",
            icon: <Image src='i/icon3.svg' mt={"15px"} />
        }
    ]

    return (
        <Flex direction={"column"} mt={"50px"} width={"100%"} maxW={"100vw"} justifyContent={"center"} alignItems={"center"}>
            <Flex direction={"column"} justifyContent={"center"} p={{ base: "0px 10px", lg: "0px" }}>
                <Text fontWeight="semibold" fontSize={"26px"} color={"#EBEBEB"} textAlign={"center"} padding={"60px 0px"}>DOWIEDZ SIĘ WIĘCEJ O PROJEKCIE SOCIALPROJECT.PL</Text>
            </Flex>
            <Flex direction={{ base: "column", lg: "row" }} position={'relative'} ml={"auto"} mr={"auto"} gap={{ base: "50px", xl: "101px" }} w={{ base: "80%", xl: "100%" }} alignItems={"center"} justifyContent={{ base: "unset", xl: 'center' }}>
                {info?.map((item, index) => (

                    <Box
                        borderRadius="30px"
                        border="2px solid white"
                        background={`url('vector.svg'), linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, rgba(61, 61, 61, 0.05) 100%)`}
                        justifyContent="center"
                        width={"340px"}
                        alignItems="center"
                        overflow={"hidden"}
                        boxShadow={'inset 0px 200px 97px -119px rgba(255,255,255,0.3)'}
                        key={index}
                    >
                        <Flex justifyContent="center" alignItems="center"  pointerEvents={"none"}>
                            {item.icon}
                        </Flex>
                        <Text fontWeight="semibold" fontSize={"22px"} color={"#EBEBEB"} textAlign={"center"}>{item.text}</Text>
                        <Text fontWeight="semibold"fontSize={"22px"} color={"white"} textAlign={"center"} textShadow={"0px 0px 6px rgba(255, 255, 255, 0.52)"} >{item.subText}</Text>
                        <Text fontWeight="light" fontSize={"18px"} color={"#EBEBEB"} textAlign={"center"} p={"44px 34px"}>{item.desc}</Text>
                    </Box>

                ))}
            </Flex>

            <Flex direction={{ base: "column", xl: "row" }} position={'relative'} ml={"auto"} mr={"auto"} w={{ base: "80%", xl: "100%" }} gap={{ base: "20px", xl: "0px" }} justifyContent={"space-between"} alignItems={"center"} p={{ base: "40px 5px", xl: "80px 5px" }}>
                <Flex direction={"row"} alignItems={"center"}>
                    <AvatarGroup size="lg">
                        {data?.avatars?.map((data, index) => (
                            <Avatar key={index} name={'Discord User'} src={data} border={"none"} width={"36px"} h={"36px"} />
                        ))}
                    </AvatarGroup>
                    <Text fontWeight="light" fontSize={"18px"} color={"#EBEBEB"} textAlign={"center"} ml={"20px"}>Dołącz do ponad {data?.userCount} osób</Text>
                </Flex>
                <Box position={'absolute'} left={0} right={0} margin={'auto'} width={"250px"} hideBelow={"xl"}>
                    <ButtonDiscord href={"https://discord.gg/socialproject"} text={"DOŁĄCZ TERAZ"} />
                </Box>
                <Box hideFrom={"xl"}>
                    <ButtonDiscord href={"https://discord.gg/socialproject"} text={"DOŁĄCZ TERAZ"} />
                    <Flex p={"28px 0px"} gap={"20px"} alignItems={"center"} justifyContent={"center"} ml={{ base: "10px", xl: "auto" }}>
                        <Box as={Link} href="https://www.youtube.com/@Socialprojectpl" target='_blank'>
                            <FaYoutube size={"18px"} color={"#EBEBEB"} cursor={"pointer"} />
                        </Box>
                        <Box as={Link} href="https://www.tiktok.com/@socialprojectpl" target='_blank'>
                            <FaTiktok size={"18px"} color={"#EBEBEB"} cursor={"pointer"} />
                        </Box>
                    </Flex>
                </Box>
                <Text fontWeight="light" fontSize={"18px"} color={"#EBEBEB"} textAlign={"right"} hideBelow={"lg"}><span style={{ color: "white" }}>Dołącz</span> już teraz do naszej wielkiej społeczności</Text>
            </Flex>
            <Flex direction={"column"} gap={{ base: "40px", lg: "60px" }} position={'relative'} ml={"auto"} mr={"auto"} alignItems={"center"} w={"100%"}>
                <Text fontWeight="semibold" fontSize={"24px"} color={"#EBEBEB"} textAlign={"center"} p={{ base: "0px 20px", lg: "0px" }}>AUTORSKI GAMEMODE ORAZ WIELE ATRAKCJI</Text>
                <Text fontWeight="light" fontSize={"18px"} color={"#EBEBEB"} textAlign={"center"} width={"auto"} maxWidth={"80%"}>Nasz autorski gamemode to serce projektu, oferujące niezapomniane doznania rozgrywki. Wzbogaciliśmy go o różnorodne elementy, obejmujące fascynujące służby mundurowe, intrygujące organizacje, ekscytującą motoryzację i emocjonujące działania przestępcze. Przygotowaliśmy także wiele innych atrakcji, które sprawią, że każda chwila spędzona w grze stanie się niezapomniana. Odkrywaj unikalne elementy, zdobywaj nagrody i doświadczaj niekończącej się rozrywki!</Text>
                <Box width={"70%"}>
                    <Divider borderWidth={"1px"} borderColor={"#EBEBEB"} />
                </Box>
            </Flex>
            <Questions />
        </Flex>
    )
}

export default InfoAbout;