import { Flex, Box } from "@chakra-ui/react";

import Navbar from "./Navbar";
import MainContent from "./MainContent";
import InfoAbout from "./InfoAbout";
import Footer from "./Footer";
// import Footerx2 from "./Footer";


function Content() {

    return (
        <>
            <Flex
                width="100%"
                direction={"column"}
                alignItems={"center"}
                marginTop={"-2px"}
                backgroundColor={"#2C2C31"}
            >
                {/* {window.innerWidth > 1600 && (
                    <Image src='postacie.png' left={700} right={0} position={"absolute"} mr={"auto"} ml={"auto"} mt={"6.25rem"} hideBelow='lg' />
                )} */}
                <Flex backgroundImage={"url('BG-min.jpg')"}
                    backgroundSize={"cover"}
                    backgroundRepeat={"no-repeat"}
                    backgroundPosition={"bottom"}
                    w={"100%"}
                    minH={window.innerHeight > 1080 ? "auto" : "100vh"}
                    justifyContent={"center"}
                    marginTop={"-2px"}
                    pb={"20px"}
                >
                    <Box w="1200px">
                        <Navbar />
                        <MainContent />
                    </Box>
                </Flex>
                <Flex backgroundImage={"url('BG2-min.jpg')"}
                    backgroundSize={"cover"}
                    backgroundRepeat={"no-repeat"}
                    w={"100%"}
                    justifyContent={"center"}
                    marginTop={"-2px"}
                >
                    <Box w="1200px">
                        <InfoAbout />
                    </Box>
                </Flex>
            </Flex>
            <Box bottom={0} left={0} bg="rgba(38, 38, 40, 1)">
                <Box maxWidth="1200px" ml={"auto"} mr={"auto"} mt={"-2px"} position="relative">
                    <Footer />
                </Box>
            </Box>
        </>
    );
}

export default Content;