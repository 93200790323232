import { Box, Flex, Image, Text } from '@chakra-ui/react'
import './Pulse.css'
import { useEffect, useState } from 'react'

function MainContent() {

    const [data, setData] = useState(null)

    useEffect(() => {
        fetch('https://api.socialproject.pl/discordOnline')
            .then(response => response.json())
            .then(data => setData(data))
            .catch(error => console.log(error))
    }, [])

    return (
        <Flex direction={'row'} alignItems={"center"}>
            <Flex gap={"30px"} direction={"column"} ml={{ base: "auto", lg: "auto" }} mr={"auto"} position={"relative"} justifyContent={{ base: "center", lg: "unset" }} p={{ base: "0px 30px", xl: "100px 0px" }} mt={"90px"} >
                <Box>
                    <Text fontWeight="semibold"fontSize={"36px"} width={"100%"} color={"#EBEBEB"}>WITAJ NA POLSKIM SERWERZE RPG SOCIALPROJECT.pl</Text>
                </Box>
                <Text fontWeight="light" fontSize={"18px"} width={"100%"} color={"#EBEBEB"}>Staw czoła rozgrywce, rozwijaj swój majątek, współpracuj z innymi i baw się
                    świetnie w unikalnych <span style={{ color: 'white' }}>wydarzeniach</span> oraz <span style={{ color: 'white' }}>aktywnościach</span></Text>
                <Text fontWeight="extralight" fontSize={"16px"} width={"100%"} color={"#EBEBEB"}>
                    Dołącz do naszej społeczności graczy oraz spraw byś został
                    legendą stanu San Andreas
                </Text>
                <Flex direction={"row"} alignItems={"center"} gap={"13px"}>
                    <span className="pulse pulse--white"></span>
                    <Text fontWeight="medium" fontSize={"18px"} color={"#EBEBEB"}>{data?.userCount} graczy online na serwerze discord</Text>
                </Flex>
            </Flex>
            <Box w={"110%"} hideBelow='md'>
                <Image src='postacie-min.png' position={'relative'} overflowX={'hidden'} />
            </Box>
        </Flex>
    )
}

export default MainContent