import { Box, Text, SimpleGrid } from "@chakra-ui/react"
import Question from "./Question"

function Questions() {
    return (
        <Box position={'relative'} ml={"auto"} mr={"auto"} alignItems={"center"} mb='40px' width={"98%"}>
            <Text fontWeight="semibold" fontSize={"24px"} color={"#EBEBEB"} mt={"40px"} textAlign={"center"} p={{base: "0px 20px", xl: "0px"}}>NAJCZĘSCIEJ ZADAWANE PRZEZ WAS <span style={{ color: "white" }}>PYTANIA</span></Text>
            <SimpleGrid columns={{base: 1, xl: 2}} spacing={2} w={"100%"} mt={"40px"} p={{base: "0px 10px", lg: "0px"}}>
                <Box minH="1px">
                    <Question question={"Kto bierze czynny udział w projekcie?"} desc={"W skład projektu wchodzi 10 osób. Każdy z nas wkłada serce i aktywnie uczestniczy w jego rozwoju. Nasz zespół składa się z: Xyrusek, L4MP3K, D4NNV, Tomo, Avillen, bover., Doman, enski, Gerak, Moeciak\nJeśli masz odpowiednie umiejętności i zechcesz dołączyć do naszego zespołu, serdecznie zapraszamy do złożenia propozycji wraz z portfolio u jednego z Administratorów RCON."} />
                </Box>
                <Box minH="1px">
                    <Question question={"Kiedy odbędzie się planowany start serwera?"} desc={"Nasz zespół w pełni wykorzystuje każdą dostępną chwilę, aby jak najszybciej przygotować serwer. Tworzenie serwera od podstaw wymaga wielkich pokładów energii i czasu. Wstępnie planujemy ukończyć serwer do końca tego roku, jednakże na obecną chwile nie jesteśmy w stanie niczego zagwarantować."} />
                </Box>
                <Box minH="1px">
                    <Question question={"Czy planowane są beta testy przed oficjalnym startem serwera?"} desc={"Ze względu na ilość osób zaangażowanych w projekt na ten moment nie planujemy beta testów. Jesteśmy przekonani, że sami uporamy się z większością błędów, jeśli jednak zadanie nas przerośnie to otworzymy rekrutację na beta testerów."} />
                </Box>
                <Box minH="1px">
                    <Question question={"W jakich lokalizacjach planowana jest rozgrywka?"} desc={"Pierwszymi lokalizacjami dostępnymi po starcie serwera będzie miasto Los Santos oraz okoliczne wioski. Wraz z rozwojem serwera pozostała część mapy również będzie dostępna dla graczy."} />
                </Box>
            </SimpleGrid>
        </Box>
    )
}

export default Questions