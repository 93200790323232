import { Box, Link } from "@chakra-ui/react"

function Button({ text, href, leftIcon }) {
    return (
        <Box
            as={Link}
            href={href}
            borderRadius="6px"
            border="1px solid white"
            background="white"
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.17)"
            color="black"
            fontWeight="medium"
            fontSize="15px"
            _hover={{
                border: "1px solid white",
                background: "linear-gradient(92deg, black 0 %, white 100 %)",
                boxShadow: "0px 0px 14px 0px rgba(255,255,255, 0.35), 0px 3px 8px 1px rgba(0, 0, 0, 0.17) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.17)",
                textDecoration: "none"
            }}
            transition="0.5s"
            padding="3px 25px"
            display="flex"
            alignItems="center"
            direction="row"
            gap="8px"
            whiteSpace={"nowrap"}
            
        >
            {leftIcon && leftIcon}
            {text}
        </Box>
    )
}

export default Button