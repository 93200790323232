import { Box, Divider, Flex, Image, Text, Link } from "@chakra-ui/react";
import { FaDiscord, FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";


function Footer() {

    const redirectToDiscord = () => {
        window.open("https://discord.gg/socialproject", "_blank");
    };
    const redirectToYoutube = () => {
        window.open("https://www.youtube.com/@Socialprojectpl", "_blank");
    }
    const redirectToTiktok = () => {
        window.open("https://www.tiktok.com/@socialprojectpl", "_blank");
    }
    const redirectToInstagram = () => {
        window.open("https://www.instagram.com/socialproject.pl", "_blank");
    }

    return (
        <Box>
            <Box>
                <Flex p={{ base: "0px", lg: "60px 0px 10px 0px" }} direction={"column"} alignItems={"flex-start"} gap={"15px"}>
                    <Flex direction={"row"} gap={{ base: "10px", md: "160px" }} alignItems={{ base: "center", lg: "flex-start" }} p={{ base: "10px", xl: "0px" }}>
                        <Image src='logo.svg' h={{ base: "50px", md: "100px" }} pointerEvents={"none"} />
                        <Flex direction={"column"} gap={"10px"}>
                            <Text fontWeight="semibold" fontSize={"18px"} color={"#EBEBEB"}>PRZYDATNE LINKI</Text>
                            <Text fontWeight="light" fontSize={"15px"} color={"#EBEBEB"} cursor={"pointer"}>Forum</Text>
                            <Text as={Link} href="https://discord.gg/socialproject" fontWeight="light" fontSize={"15px"} color={"#EBEBEB"} cursor={"pointer"}>Discord</Text>
                            <Text fontWeight="light" fontSize={"15px"} color={"#EBEBEB"} cursor={"pointer"}>Wikipedia</Text>
                        </Flex>
                        <Flex direction={"column"} gap={"10px"}>
                            <Text fontWeight="semibold" fontSize={"18px"} color={"#EBEBEB"}>&nbsp;</Text>
                            <Text fontWeight="light" fontSize={"15px"} color={"#EBEBEB"} cursor={"pointer"}>Administracja</Text>
                            <Text fontWeight="light" fontSize={"15px"} color={"#EBEBEB"} cursor={"pointer"}>Sklep premium</Text>
                            <Text fontWeight="light" fontSize={"15px"} color={"#EBEBEB"} cursor={"pointer"}>Ranking</Text>
                        </Flex>
                    </Flex>
                </Flex>
                <Box p={"0px"} mt={{ base: "20px", md: "60px" }} ml={{ base: "10px", xl: "unset" }} mr={{ base: "10px", xl: "unset" }} position={'relative'}>
                    <Divider borderWidth={"1px"} borderColor={"#EBEBEB"} />
                </Box>

                <Flex direction={{ base: "column", lg: "row" }} p={{ base: "10px", xl: "15px 0px" }} alignItems={{ base: "center", md: "unset" }} >
                    <Flex direction={"row"} p={"10px 0px"} gap={"20px"} hideFrom={"lg"}>
                        <FaDiscord size={"18px"} color={"#EBEBEB"} onClick={redirectToDiscord} cursor={"pointer"} />
                        <FaYoutube size={"18px"} color={"#EBEBEB"} onClick={redirectToYoutube} cursor={"pointer"} />
                        <FaTiktok size={"18px"} color={"#EBEBEB"} onClick={redirectToTiktok} cursor={"pointer"} />
                        <FaInstagram size={"18px"} color={"#EBEBEB"} onClick={redirectToInstagram} cursor={"pointer"} />
                    </Flex>
                    <Text fontWeight="regular" fontSize={"15px"} color={"#EBEBEB"} mr={"3px"}>© 2024 socialproject.pl <span style={{ color: "#EBEBEB", fontFamily: "ClashGrotesk-Variable", fontWeight: "extralight" }}>All rights reserved. </span></Text>
                    <Text fontWeight="extralight" fontSize={"15px"} color={"#EBEBEB"}>Designed by <Link color="white" href="https://www.behance.net/dannyoo" target="_blank" >D4NNV</Link>. Made by <Link color="white" href="https://destriusz.me" target="_blank" >Destriusz</Link>.</Text>
                    <Flex direction={"row"} ml={"auto"} p={"0px 0px"} gap={"20px"} hideBelow={"lg"}>
                        <FaDiscord size={"18px"} color={"#EBEBEB"} onClick={redirectToDiscord} cursor={"pointer"} />
                        <FaYoutube size={"18px"} color={"#EBEBEB"} onClick={redirectToYoutube} cursor={"pointer"} />
                        <FaTiktok size={"18px"} color={"#EBEBEB"} onClick={redirectToTiktok} cursor={"pointer"} />
                        <FaInstagram size={"18px"} color={"#EBEBEB"} onClick={redirectToInstagram} cursor={"pointer"} />
                    </Flex>
                </Flex>
            </Box>
        </Box>
    );
}

export default Footer;