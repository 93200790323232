import { FaChevronDown, FaChevronLeft } from "react-icons/fa";
import { useState } from 'react';
import { Box, Flex, Text, Divider } from '@chakra-ui/react';
import { AnimateOnChange } from 'react-animation'

function Question({ question, desc }) {
    const [visible, setVisible] = useState(false)


    const toggleVisible = () => {
        setVisible(prevVisible => !prevVisible);
    }

    return (
        <Flex direction={"column"}>
            <Box background={"linear-gradient(270deg, rgba(55, 55, 64, 0.45) 0%, rgba(56, 56, 67, 0.45) 99.36%)"} onClick={toggleVisible}>
                <Flex direction={"row"}>
                    <Text fontWeight="regular" fontSize={"18px"} color={"#EBEBEB"} padding={"16px"} style={{ userSelect: "none" }}>{question}</Text>
                    <Flex ml={"auto"} r={0} alignItems={"center"} p={"10px"}>
                        {visible ? (<FaChevronDown size={"16px"} color='#BEBEBE' />) : (<FaChevronLeft size={"16px"} color='#BEBEBE' />)}
                    </Flex>
                </Flex>
                <Divider borderWidth={"1px"} borderColor={"#505056;"} />
            </Box>
            <AnimateOnChange
                durationOut={250}>
                {visible && (<Box background={"linear-gradient(270deg, rgba(55, 55, 64, 0.45) 0%, rgba(56, 56, 67, 0.45) 99.36%)"}>
                    <Text fontWeight="regular" fontSize={"16px"} color={"#EBEBEB"} padding={"16px"} whiteSpace={'pre-line'}>{desc}</Text>
                </Box>)}
            </AnimateOnChange>
        </Flex>
    )
}

export default Question