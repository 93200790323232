import { Box, Link } from "@chakra-ui/react"
import { FaDiscord } from "react-icons/fa"

function Button({ text, href, leftIcon }) {
    return (
        <Box
        as={Link}
        href={href}
            borderRadius="1000px"
            border="1px solid #623DB2"
            background="linear-gradient(92deg, #5F29D1 0%, #482E80 100%)"
            boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.17)"
            color="white"
            fontWeight="medium"
            fontSize="15px"
            _hover={{
                boxShadow: "0px 0px 72.2px 0px rgba(120, 61, 178, 0.44), 0px 2px 4px 0px rgba(0, 0, 0, 0.17)",
                textDecoration: "none"
            }}
            transition="0.5s"
            padding="8px 35px"
            display="flex"
            alignItems="center"
            direction="row"
            gap="8px"
            whiteSpace={"nowrap"}
            justifyContent={"center"}
            cursor={"pointer"}
        >
            <FaDiscord color="white" size={"18px"}/>
            {text}
        </Box>
    )
}

export default Button